import { useTranslation } from "react-i18next";

export default function AboutMe() {
  const { t } = useTranslation();

  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        <img src="./img/moi.jpg" alt="AboutMe" />
      </div>
      <div className="hero--section--content-box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">{t('about.title')}</p>
          <h1 className="skills-section--heading">{t('about.heading')}</h1>
          <p className="hero--section-description">
            {t('about.description1')}
          </p>
          <p className="hero--section-description">
            {t('about.description2')}
          </p>
        </div>
      </div>
    </section>
  );
}
