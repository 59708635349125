import { useTranslation } from "react-i18next";

export default function HeroSection() {
  const { t } = useTranslation();

  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content-box">
        <div className="hero--section--content">
          <p className="section--title">{t('hero.hello')}</p>
          <h1 className="hero--section--title">
            <span className="hero--section--title--color">
              {t('hero.student')}
            </span>{" "}
            <br />
            {t('hero.passion')}
          </h1>
          <p className="hero--section--description">
            {t('hero.description')}
            <br />
            {t('hero.goal')}
          </p>
        </div>
      </div>
      <div className="hero--section--img">
        <img src="./img/moiherosection.jpg" alt="Hero Section" />
      </div>
    </section>
  );
}
