import { useTranslation } from "react-i18next";
import data from "../../data/index.json";

export default function MySkills() {
  const { t, i18n } = useTranslation();
  const currentLang = (i18n.language || 'fr').split('-')[0];

  return (
    <section className="skills--section" id="mySkills">
      <div className="portfolio--container">
        <p className="section--title">{t('skills.title')}</p>
        <h2 className="skills--section--heading">{t('skills.heading')}</h2>
      </div>
      <div className="skills--section--container">
        {data?.skills?.map((item, index) => (
          <div key={index} className="skills--section--card">
            <div className="skills--section--img">
              <img src={item.src} alt="Product Chain" />
            </div>
            <div className="skills--section--card--content">
              <h3 className="skills--section--title">{item.title[currentLang]}</h3>
              <p className="skills--section--description">{item.description[currentLang]}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
