import { useTranslation } from "react-i18next";

export default function ContactMe() {
  const { t } = useTranslation();

  return (
    <section id="Contact" className="contact--section">
      <div>
        <p className="sub--title">{t('contact.title')}</p>
        <h2>{t('contact.heading')}</h2>
        <p className="text-lg-with-margin">
          {t('contact.description1')}
        </p>
        <a href="mailto:thibaut.alessi@free.fr">thibaut.alessi@free.fr</a>
        <p className="text-lg">
          {t('contact.description2')}
        </p>
      </div>
    </section>
  );
}
